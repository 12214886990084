.margin-left-10 {
  margin-left: 10px;
}

.no-border{
  border: none !important;
}

.border-top {
  margin-top: 20px;
  padding-top: 20px !important;
  border-top: 1px solid #ddd;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
