$input-error-color: #FF5252;

@import url(https://fonts.googleapis.com/css?family=Arvo:400,700);

@import "responsive.scss";
@import "helpers.scss";

body.minimal {
  background: #eee;
}

.content-header {
  position: relative;
  padding: 20px 20px 0 20px;
}

.content {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.btn {
  border-radius: 0;
}

.btn.pull-right {
  margin-left: 10px;
}

.btn.pull-left {
  margin-right: 10px;
}

.box-header,
.box-body,
.box-footer {
  padding: 20px;
}

.box-header>.box-tools {
  position: absolute;
  right: 15px;
  top: 10px;
}

.box .nav {
  margin-bottom: 20px;
}

form.box .row:last-child > .form-group {
  margin-bottom: 0;
}

.help-block {
  margin-top: 0;
  font-size: 12px;
  color: #737373;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  color: #888;
}

/*
#flash-notice {
  position: absolute;
  display: none;
  top: 25px;
  right: 20px;
  width: 70%;
  text-align: right;
  font-size: 12px;
  line-height: 1.2;
  z-index: 10000;
}
*/

#flash-notice {
  position: fixed;
  display: none;
  width: 600px;
  top: 30px;
  left: 50%;
  margin-left: -300px;
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  box-shadow: 0 0 5px 0 rgba(0,0,0,.2);
  z-index: 10000;
}

#flash-notice > .icon {
  float: left;
  width: 40px;
  text-align: center;
  font-size: 20px;
  line-height: 40px;
  background: #00a65a;
}

#flash-notice > .body {
  float: left;
  width: 560px;
  height: 40px;
  padding: 5px;
  color: #666;
  background: #fff;
}

#flash-notice.success {

}

span.highlight {
  color: #000;
  background: #feff9a;
}

.clickable {
  cursor: pointer;
}

.sidebar > .user-panel > .avatar {
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #999;
  border-radius: 50%;
  background: #fff;
}

.user-menu .user-header > .avatar {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 14px;
}

label {
  margin-bottom: 0;
  font-weight: normal;
  line-height: 20px;
}

section.content {
  font-size: 12px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {

}

.table > tbody > tr.inactive {
  background: #eee;
}

.nav-tabs-custom > .nav-tabs {
}

.tab-header {

}

.nav-tabs-custom > .nav-tabs > li.active > a {
}

.login-logo, .register-logo {
  margin-bottom: 0;
}

.login-logo .message, .register-logo .message {
  margin-bottom: 20px;
  font-size: 12px;
  color: #666;
}

.login-box > .login-box-body {
  margin: 20px 0;
}

/**
* ##############################################################
* Error messages
* ##############################################################
*/
.input-error {
  display: none;
  position: absolute;
  top: -40px;
  border-radius: 2px;
  font-size: 14px;
  background: $input-error-color;
}

.input-error > .error-arrow {
  position: absolute;
  left: 7px;
  bottom: -7px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

  border-top: 7px solid $input-error-color;
}

.input-error > a.error-close,
.input-error > a.error-close:hover {
  float: right;
  padding: 3px 7px 3px 0;
  font-size: 18px;
  color: #fff;
  text-decoration: none;
}

.input-error > .error-inner {
  float: left;
  padding: 5px 7px 5px 7px;
}

.form-group.error .input-error {
  display: block;
}

.has-error .fr-toolbar {
  border-top: 5px solid #dd4b39;
}

/**
* ##############################################################
* Error messages
* ##############################################################
*/

.input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  border-radius: 0;
}

form {
  .row {
    margin-right: -10px;
    margin-left: -10px;
  }

  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.form-control {
  color: #333;
}

form hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 10px;
}

form label {
  font-size: 12px;
}
form label:after {
  /*content: ": "*/
}

.clear-search {
  position: absolute;
  right: 40px;
  font-size: 24px;
  line-height: 30px;
  z-index: 10;
}

.input-icon-right {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 0 30px 0 10px;
  line-height: 30px;
}

form .actions {
  margin-top: 10px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.checkbox-heading {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.custom-checkboxes {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.custom-checkboxes > .inner {
  margin-top: -2%;
  margin-left: -2%;
}

.custom-checkboxes label.checkbox {
  float: left;
  width: 48%;
  margin: 2% 0 0 2%;
  padding: 5px;
  line-height: 30px;
  text-align: center;
  background: #eee;
}

.custom-checkboxes label.checkbox.checked {
  color: #fff;
  background: #00a65a;
}

.custom-checkboxes .radio input[type="radio"],
.custom-checkboxes .radio-inline input[type="radio"],
.custom-checkboxes .checkbox input[type="checkbox"],
.custom-checkboxes .checkbox-inline input[type="checkbox"] {
  display: none;
  position: static;
  margin-top: 0;
  margin-left: 0;
}

/* Tabs */
.nav-tabs-custom .tab-title {
  margin: 0;
  margin-bottom: 20px;
}

.tab-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}

.tab-header > .fa,
.tab-header > .glyphicon,
.tab-header > .ion,
.tab-header .tab-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}

.tab-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}

.tab-header,
.tab-body,
.tab-content,
.nav-tabs-custom > .tab-content,
.tab-footer {
  padding: 20px;
}

.nav-tabs-custom > .nav-tabs > li {
  margin-right: 0;
}

.nav-tabs-custom > .nav-tabs > li > a:hover {
  color: #3c8dbc;
}

.tab-header > .logo {
  width: auto;
  max-height: 40px;
}

.card-number {
  padding: 3px 5px;
  font-family: 'Arvo', sans-serif;
  color: #fff;
  font-weight: 700;
  line-height: 1;
  border-radius: 2px;
  background: #2b4959;
}

#ui-datepicker-div {
  z-index: 5 !important;
}
