.tt-query,
.tt-hint {
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 542px;
  min-width: 100%;
  margin: 0;
  background-color: #fff;
  border-top: 1px solid #ddd;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
  -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.2);
}

.tt-header {
  background: #ddd;
}

.tt-suggestion, .tt-header {
  line-height: 30px;
  height: 30px;
  font-size: 12px;
  border: 1px solid #ddd;
  border-top: none;
}

.tt-suggestion:hover {
  cursor: pointer;
  background-color: #eee;
}

.tt-suggestion.tt-cursor {
  background-color: #eee;

}

.tt-suggestion p {
  margin: 0;
}

.tt-menu .col-1,
.tt-menu .col-2,
.tt-menu .col-3,
.tt-menu .col-4 {
  float: left;
  padding: 0 10px;
  line-height: 30px;
  border-right: 1px solid #ddd;
}

.tt-menu .col-1 {
  width: 80px;
}

.tt-menu .col-2 {
  width: 220px;
}

.tt-menu .col-3 {
  width: 140px;
}

.tt-menu .col-4 {
  width: 100px;
  border-right: none;
}
