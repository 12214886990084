/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .custom-checkboxes {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .custom-checkboxes > .inner {
    margin-top: -2%;
    margin-left: -2%;
  }

  .custom-checkboxes label.checkbox {
    width: 23%;
    margin: 2% 0 0 2%;
    line-height: 20px;
  }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .custom-checkboxes > .inner {
    margin-top: -1%;
    margin-left: -1%;
  }

  .custom-checkboxes label.checkbox {
    width: 19%;
    margin: 1% 0 0 1%;
  }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}